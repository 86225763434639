import type { Request, Response } from 'express';
import type { PageContextServer } from '@/renderer/types';

export const CATEGORY = {
  B2B_CALC_AB: 'b2b_calc_im_ab',
};

export const AB_SETTINGS: Record<string, any> = {
  [CATEGORY.B2B_CALC_AB]: {
    '/online-stores/': {
      groups: { A: '/online-stores/', B: '/online-stores_new/' },
      visitParam: 'calc_im_AB',
    },
  },
};

const CHAR_CODE_A = 65;

export const initTest = (req: Request, res: Response, name: string, count = 3) => {
  const keys = Array.from(Array(count), (_, i) => String.fromCharCode(CHAR_CODE_A + i));

  const value = req.cookies[name];

  if (!value || !keys.includes(value)) {
    const newValue = keys[Math.floor(Math.random() * keys.length)];

    req.cookies[name] = newValue;
    res.cookie(name, newValue);

    return newValue;
  }

  return value;
};

export const getCookieValueFromPageContext = (pageContext: PageContextServer, name: string) => {
  return pageContext.cookies[name];
};
